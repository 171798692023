<template>


    <!-- BEGIN: Content-->
    <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper container-xxl p-0">
            <div class="content-header row">
                <div class="content-header-left col-md-9 col-12 mb-2">
                    <div class="row breadcrumbs-top">
                        <div class="col-12">
                            <h2 class="content-header-title float-start mb-0">Analytics</h2>
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Insights on your recruitment activities</a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
                    <div class="mb-1 breadcrumb-right">
                        <base-card-drop-down>
                            <template #title>
                                <i class="bi bi-plus" ></i> Options
                            </template>
                            <template #default>
                                <a class="dropdown-item" href="#">
                                    <i class="bi bi-check-circle " ></i>
                                    Recruitment analysis
                                </a>
                                <a class="dropdown-item" href="#">
                                    <i class="bi bi-circle bs-icon-tiny" ></i>
                                    Test Analysis
                                </a>
                                <a class="dropdown-item" href="#">
                                    <i class="bi bi-circle " ></i>
                                    Admin Analysis
                                </a>
                                <a class="dropdown-item" href="#" >
                                    <i class="bi bi-circle " ></i>
                                    Subscription stats
                                </a>
                            </template>
                        </base-card-drop-down>
                    </div>
                </div>
            </div>
            <div class="content-body">
                <!-- Dashboard Ecommerce Starts -->

                <base-list-tag tagColor="primary" class="mt-3">
                    Recruitment stats
                </base-list-tag>

                <reports-recruitment-stats-tiles></reports-recruitment-stats-tiles>
                <reports-recruitment-stats-tiles></reports-recruitment-stats-tiles>
                <reports-recruitment-stats-tiles></reports-recruitment-stats-tiles>

                <base-list-tag tagColor="primary" class="mt-3">
                    Administrative stats
                </base-list-tag>

                <reports-recruitment-stats-tiles></reports-recruitment-stats-tiles>
                <reports-recruitment-stats-tiles></reports-recruitment-stats-tiles>
                <reports-recruitment-stats-tiles></reports-recruitment-stats-tiles>

                <base-list-tag tagColor="primary" class="mt-3">
                    Expenditure stats
                </base-list-tag>

                <reports-recruitment-stats-tiles></reports-recruitment-stats-tiles>
                <reports-recruitment-stats-tiles></reports-recruitment-stats-tiles>
                <reports-recruitment-stats-tiles></reports-recruitment-stats-tiles>

                <!-- Dashboard Ecommerce ends -->

            </div>
        </div>
    </div>
    <!-- END: Content-->


</template>

<script>
// import the core functions from vuex
import { useStore } from 'vuex';

import ReportsRecruitmentStatsTiles from '@/components/reports/ReportsRecruitmentStatsTiles.vue';

export default{
    components: {
        ReportsRecruitmentStatsTiles
    },
    setup(){
        // get the global vuex store values
        const store = useStore();

        // use the dispatch function to call an action that calls a mutation
        store.dispatch("globals/executeSetNavMenu",true);
    }
}
</script>
